<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader :title="headercontent.title" />
        </v-col>
        <v-col md="12">
          <v-data-table
            :items="allReports.results"
            :headers="headers"
            :server-items-length="allReports.pagination.total"
            :options.sync="options"
            :loading="loading"
          >
          </v-data-table>
          <div v-if="loading">
            <v-skeleton-loader type="image" height="200"></v-skeleton-loader>
            <v-skeleton-loader type="image" height="200"></v-skeleton-loader>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Report",
  components: { PageHeader },
  data: () => ({
    headercontent: {
      title: "Report List",
    },
    loading: true,
    headers: [
      { text: "Title", value: "title", sortable: false },
      { text: "Description", value: "description", sortable: false },
      { text: "User", value: "userHandle", sortable: false },
    ],
    options: {
      itemsPerPage: 5,
      page: 1,
    },
  }),
  computed: {
    ...mapGetters(["allReports"]),
  },
  methods: {
    ...mapActions(["getReports"]),
    async reloadReports() {
      this.loading = true;
      await this.getReports({
        limit: this.options.itemsPerPage,
        skip: (this.options.page - 1) * this.options.itemsPerPage,
      });
      this.loading = false;
    },
  },
  async created() {
    await this.reloadReports();
  },
};
</script>
